import type { PlayersActivity } from "@/types";

type EventData<TData> = {
	type: string;
	action: string;
	data: TData;
};

const MAX_SAVED_DATA_SIZE = 15;

const useRecentGamesWinners = () => {
	const playersActivity = useState<Array<PlayersActivity>>("playersActivity", () => []);

	const { data: fetchedPlayersActivity } = useAsyncFetch({
		path: "/rest/players-activity/",
		method: "get",
		options: {
			cached: true,
			default: () => [],
			transform: (data) => data.data ?? []
		},
		fetchOptions: {
			query: {
				limit: 6
			},
			onResponse: ({ response }) => {
				playersActivity.value = response._data.data ?? [];
			}
		}
	});

	const { add, remove } = useSockets();

	add("addPlayerLastAction", ({ data }: EventData<PlayersActivity>) => {
		playersActivity.value = [data, ...playersActivity.value].slice(0, MAX_SAVED_DATA_SIZE);
	});

	onUnmounted(() => {
		remove("addPlayerLastAction");
	});

	return { playersActivity, fetchedPlayersActivity };
};

export default useRecentGamesWinners;
