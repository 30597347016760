<script lang="ts" setup>
import type { PlayersActivityGame } from "@/types";

const { open } = useAppModals();
const { handleOpenGame } = useOpenGame(open);
const loginGuard = useLoginGuard();

const { playersActivity, fetchedPlayersActivity } = useRecentGamesWinners();
const documentVisibleState = useDocumentVisibility();

const handleClickCard = (item: PlayersActivityGame) => {
	loginGuard({
		success: () => {
			if (!item?.slug) {
				return;
			}
			handleOpenGame(item?.slug);
			dispatchGAEvent({
				event: "click_button",
				location: item?.slug ?? "",
				button_name: "gameplay",
				form_name: "LATEST WINNERS"
			});
		},
		fail: () => open("LazyOModalSignup")
	});
};
</script>
<template>
	<div v-if="fetchedPlayersActivity?.length" class="list-winners-wrapper" data-tid="last-winners">
		<MHomeSectionHeader title="Latest Winners" icon="home/latest" />
		<ClientOnly>
			<AHorizontalScroll shadowEffect>
				<div v-if="documentVisibleState === 'hidden'" class="box-winners">
					<TransitionGroup name="list-transform">
						<MHomeActivityFeedCard
							v-for="(item, index) in fetchedPlayersActivity"
							:key="`card-${item.type}-${item.id}`"
							:winner="item"
							:data-tid="`last-winners-card-${index}`"
							@open-game="handleClickCard"
						/>
					</TransitionGroup>
				</div>
				<div v-if="documentVisibleState === 'visible'" class="box-winners">
					<TransitionGroup name="list-transform">
						<MHomeActivityFeedCard
							v-for="(item, index) in playersActivity"
							:key="`card-${item.type}-${item.id}`"
							:winner="item"
							:data-tid="`last-winners-card-${index}`"
							@open-game="handleClickCard"
						/>
					</TransitionGroup>
				</div>
			</AHorizontalScroll>
		</ClientOnly>
	</div>
</template>
<style lang="scss" scoped>
div.list-winners-wrapper {
	display: flex;
	flex-direction: column;
	gap: 12px;
	overflow: hidden;
	padding: 0 16px;

	@include media-breakpoint-up(lg) {
		gap: 16px;
		padding: 0;
	}
}

.box-winners {
	display: flex;
	gap: 12px;

	@include media-breakpoint-up(md) {
		gap: 16px;
	}

	&:deep(img) {
		pointer-events: none;
	}
}

.list-transform-move {
	transition: transform 0.8s;
}

.list-width-enter-active,
.list-width-leave-active {
	transition:
		min-width 0.8s,
		width 0.8s,
		opacity 0.8s;
}
.list-width-enter-from,
.list-width-leave-to {
	opacity: 0;
	width: 0 !important;
	min-width: 0 !important;
	max-width: 0 !important;
}

@include media-breakpoint-down(md) {
	.scroll-container {
		width: calc(100% + 32px);
		margin-left: -16px;
		padding-left: 16px;
		padding-right: 16px;
	}
}
</style>
